<template>
  <div id="error">
    <h2>Error 404</h2>
  </div>
</template>

<script>
export default {
  name: 'Error404'
}
</script>

<style lang="scss" scoped>
</style>
